<template>
	<div class="page-container">
		<nav-bar title="学员订单查询" id="navbar"></nav-bar>
		<van-form @submit="onSearch" ref="formData" action="" v-if="!showResult">
			<van-field
				v-model="certNum"
				name="certNum"
				label="身份证号"
				placeholder="身份证号码"
				maxlength="18"
			/>
			<div style="margin: 16px">
				<van-button round block type="info" native-type="submit">查询</van-button>
			</div>
		</van-form>
		<div v-if="showResult">
			<van-cell-group inset title="查询结果">
				<van-cell title="身份证号" :value="certNum" />
				<van-cell title="订单总数" :value="totalCount" value-class="total-num" />
			</van-cell-group>

			<van-cell-group inset :title="item.orderId" v-for="(item, index) of invoiceList" :key="index">
				<van-cell title="订单金额" :value="item.fee" />
				<van-cell title="创建时间" :value="item.createTime" />
				<van-cell title="备注说明" :value="item.remark" />
				<van-cell title="未开发票" value="申请开票" style="color: #F56C6C;" v-if="item.invoiceState===0" is-link @click="openInvoiceApply(item)" />
				<van-cell title="开票办理中" value="请您等待" style="color: #E6A23C;" v-if="item.invoiceState===1" />
				<van-cell title="发票已开" value="请您查收" style="color: #67C23A;" v-if="item.invoiceState===2" />
			</van-cell-group>

			<div style="margin: 16px">
				<van-button round block type="info" @click="goBack">返回</van-button>
			</div>
		</div>
		<!-- 发票申请表单 -->
		<van-popup v-model="showApplyForm" position="left" :style="{ height: '100%', width: '100%' }" closeable>
			<div class="form-area">
				<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="volume-o">
					提醒：请您尽可能将申请信息填写完整并仔细核对无误后再提交，谢谢！
				</van-notice-bar>
				<van-form @submit="handleInvoice" ref="formData" action="">
					<div class="title">
						<span style="color: #888;">订单编号：{{form.orderId}}</span>
					</div>
					<div class="title">
						<span style="color: #888;">开票金额：<span class="total-num">{{form.fee}} 元</span></span>
					</div>
					<van-field name="radio" label="发票类型">
						<template #input>
							<van-radio-group v-model="form.invoiceType" @change="handleInvoiceTypeChanged">
								<van-radio name="1">增值税电子发票</van-radio>
								<van-radio name="2" style="margin: 15px 0;">增值税普通发票</van-radio>
								<van-radio name="3">增值税专用发票</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-notice-bar
						wrapable
						:scrollable="false"
						left-icon="info-o"
						:text="showMsg"
						v-if="showMsg !== ''"
					/>
					<van-field name="radio" label="发票抬头">
						<template #input>
							<van-radio-group v-model="form.invoiceHeader" direction="horizontal">
								<van-radio name="1">个人</van-radio>
								<van-radio name="2">单位</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field
						v-model="form.headerName"
						type="input"
						name="headerName"
						center
						clearable
						:label="form.invoiceHeader === '2' ? '单位名称' : '抬头名称'"
						:placeholder="form.invoiceHeader === '2' ? '请填入单位名称' : '请填入抬头名称'"
					>
					</van-field>
					<van-field
						v-model="form.dutyParagraph"
						type="input"
						name="dutyParagraph"
						center
						clearable
						label="纳税人识别号"
						placeholder="请填入纳税人识别号"
						v-if="form.invoiceHeader === '2'"
					>
					</van-field>
					<van-field
						v-model="form.address"
						type="input"
						name="address"
						center
						clearable
						label="注册地址"
						placeholder="请填入注册地址"
						v-if="form.invoiceHeader === '2'"
					>
					</van-field>
					<van-field
						v-model="form.bank"
						type="input"
						name="bank"
						center
						clearable
						label="开户银行"
						placeholder="请填入开户银行"
						v-if="form.invoiceHeader === '2'"
					>
					</van-field>
					<van-field
						v-model="form.bankAccount"
						type="input"
						name="bankAccount"
						center
						clearable
						label="银行账户"
						placeholder="请填入银行账户"
						v-if="form.invoiceHeader === '2'"
					>
					</van-field>
					<van-field
						v-model="form.payeeEmail"
						type="input"
						name="payeeEmail"
						center
						clearable
						label="邮箱地址"
						placeholder="请填入电子邮箱地址"
					>
					</van-field>
					<van-field
						v-model="form.payeeName"
						type="input"
						name="payeeName"
						center
						clearable
						label="收票人姓名"
						placeholder="请填入收票人姓名"
					>
					</van-field>
					<van-field
						v-model="form.payeeMobile"
						type="input"
						name="payeeMobile"
						center
						clearable
						label="收票人电话"
						placeholder="请填入收票人电话"
					>
					</van-field>
					<van-field
						v-model="form.payeeAddress"
						type="input"
						name="payeeAddress"
						center
						clearable
						label="收票人地址"
						placeholder="请填入收票人地址"
					>
					</van-field>
					<div style="margin: 16px">
						<van-button round block type="info" native-type="submit">提交</van-button>
					</div>
				</van-form>
			</div>
		</van-popup>
		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import {NavBar, Toast} from "vant";
import {invoiceQuery, invoiceApply} from "@/api/user";
export default {
	name: "invoice-index",
	components: { NavBar },
	data() {
		return {
			studentName: '',
			certNum: '',
			studentId: 0,
			totalCount: 0,
			showResult: false,
			showLoading: false,
			showApplyForm: false,
			title: null,
			invoiceList: [],
			form: {},
			showMsg: ''
		}
	},
	methods: {
		onSearch() {
			if(this.certNum === '') {
				Toast.fail("请您输入身份证号码");
				return;
			}
			invoiceQuery(this.certNum)
				.then((res) => {
					if(res) {
						if (res.length === 0) {
							this.invoiceList = [];
							Toast.fail("抱歉，未查询到订单信息");
						} else {
							this.invoiceList = res;
						}
						this.totalCount = res.length;
						this.showResult = true;
					}
					this.showLoading = false;
				})
				.catch(() => {
					this.showLoading = false;
				});
		},
		handleInvoice() {
			if (this.form.invoiceType == null) {
				Toast.fail('请选择发票类型')
				return
          	}
			if (this.form.invoiceHeader == null) {
				Toast.fail('请选择发票抬头')
				return
			}
			if(this.form.invoiceHeader == 1){
				if(this.form.headerName == null){
				Toast.fail('请填入抬头名称')
				return
				}
			}
			if (this.form.invoiceHeader == 2) {
				if(this.form.headerName == null){
				Toast.fail('请填入单位名称')
				return
				}
				if(this.form.dutyParagraph == null){
				Toast.fail('请填入纳税人识别号')
				return
				}
				if(this.form.address == null){
				Toast.fail('请填入注册地址')
				return
				}
				if(this.form.bank == null){
				Toast.fail('请填入开户银行')
				return
				}
				if(this.form.bankAccount == null){
				Toast.fail('请填入银行账户')
				return
				}
				if(this.form.dutyParagraph == null){
				Toast.fail('请填入纳税人识别号')
				return
				}
			}
			if (this.form.invoiceHeader == null) {
				Toast.fail('请选择发票抬头')
				return
			}
			if (this.form.payeeEmail == null) {
				Toast.fail('请填入电子邮箱地址')
				return
			}else{
				if(!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/.test(this.form.payeeEmail)){
				Toast.fail("请输入正确的邮箱地址")
				return
				}
			}
			if (this.form.payeeName == null) {
				Toast.fail('请填入收票人姓名')
				return
			}
			if (this.form.payeeMobile == null) {
				Toast.fail('请填入收票人电话')
				return
			}else{
				if(!/^1[34578]\d{9}$/.test(this.form.payeeMobile)){
				Toast.fail('请填入正确的电话')
				return
				}
			}
			this.form.invoiceState = 1;
			this.showLoading = true;
			invoiceApply(this.form)
				.then(() => {
					this.showLoading = false;
					Toast.success("发票申请提交成功，请等待工作人员办理")
					this.form = {};
					this.showApplyForm = false;
					this.onSearch();
				})
				.catch(() => {
					this.showLoading = false;
					Toast.fail("发票申请提交失败，请稍候重试或联系管理员");
				});
		},
		openInvoiceApply(invoice) {
			this.form = {};
			this.form.orderId = invoice.orderId;
			this.form.fee = invoice.fee;
			this.showApplyForm = true;
		},
		handleInvoiceTypeChanged (o) {
			if (o === '1') {
				this.showMsg = '注：增值税电子发票提交成功后，预计2个工作日内开出，请注意查收您的邮件。'
			} else if (o === '2') {
				this.showMsg = '注：增值税普通发票即普通纸质发票，提交成功后，预计7个工作日内邮寄。'
			} else if (o === '3') {
				this.showMsg = '注：增值税专用发票提交成功后，预计7个工作日内邮寄。'
			} else {
				this.showMsg = ''
			}
		},
		goBack() {
			this.showResult = false;
		}
	}
}
</script>

<style scoped>
#navbar {
	background-color: #409eff;
}
#navbar /deep/ .van-ellipsis {
	color: #fff !important;
}
#navbar /deep/ .van-nav-bar__text {
	color: #fff !important;
}
.form-area {
	margin-top: 46px;
}
.title {
	margin: 0;
	padding: 16px 16px 16px;
	color: rgba(69, 90, 100, 0.6);
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}
.total-num {
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #E10900;
}
.van-cell-group__title {
	background-color: #f5f5f5;
}
</style>
